


























  import { Component, Prop, Vue } from 'vue-property-decorator'
  import 'vue-slider-component/dist-css/vue-slider-component.css'
  import 'vue-slider-component/theme/default.css'
  import { FilteringAttributeType, MappedPriceFilter } from '@one/types'
  import { PricingFilterChangeEvent } from '~/utils/ProductsListing'

  @Component({
    components: {
      VueSlider: () => import('vue-slider-component/dist-css/vue-slider-component.umd.min.js'),
    },
  })
  export default class OnePriceFilter extends Vue {
    name: string = 'OnePriceFilter'

    @Prop({
      required: true,
      type: Object,
    })
    filter!: MappedPriceFilter;

    @Prop({
      required: true,
      type: Array,
    })
    value!: Array<number>;

    @Prop({
      required: false,
      type: String,
    })
    priceType?: 'NET' | 'GROSS';

    @Prop({
      default: false,
      type: Boolean,
    })
    loading!: boolean;

    get currentPriceType(): 'NET' | 'GROSS' {
      return this.priceType ?? 'NET'
    }

    get min(): number {
      return this.filter[this.currentPriceType].min
    }

    get max(): number {
      return this.filter[this.currentPriceType].max
    }

    onMinValueChanged(newMinValue: number) {
      return this.onRangeValueChanged([newMinValue, this.value[1]])
    }

    onMaxValueChanged(newMaxValue: number) {
      return this.onRangeValueChanged([this.value[0], newMaxValue])
    }

    onRangeValueChanged(newRangeValues: Array<Number>) {
      const isValid: boolean = this.validateInputValue(newRangeValues)
      if (isValid) {
        this.$emit('change', {
          type: FilteringAttributeType.PRICE,
          value: newRangeValues,
        } as PricingFilterChangeEvent)
      }
    }

    validateInputValue(value: Array<Number>): boolean {
      // eslint-disable-next-line max-len
      return value[0] >= this.min && this.max >= value[1] && this.min < this.max && value[0] <= value[1]
    }
  }
